/**
 * Page (default)
 */

import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { PageLayout } from '@layout';
import { PageBuilder } from '@pagebuilder';

const Page = ({ data, children, pageContext }) => {
  const components = get(data, 'wordpressPage.acf.components');
  const page = get(data, 'wordpressPage', {})
  const pageChildren = get(data, 'allWordpressPage.nodes', []) || [];
  const pageTitle = get(page, 'title', null);
  const template = page.template ? page.template.replace('page-', '').replace('.php', '') : 'page';
  const path = get(page, 'path', null);
  const acf = get(page, 'acf', {}) || {}
  const seo = get(acf, 'seo', {});
  
  const siteMeta = {
    ...seo,
    pageTitle,
    path,
    noRobots: get(pageContext, 'noRobots'),
  }
  const pageBuilderData = get(acf, 'page_builder_page', null);
  const pageHeader = {
    eyebrow: get(acf, 'page_header_eyebrow', null),
    primary: get(acf, 'page_header_primary_title', null),
    secondary: get(acf, 'page_header_secondary_title', null),
    intro: get(acf, 'page_header_intro', null),
    primary_cta: get(acf, 'page_header_primary_cta', null),
    secondary_cta: get(acf, 'page_header_secondary_cta', null),
    image: get(acf, 'page_header_image', null),
  }

  // console.log(`📦 Page Query (${template})`, data);
  // console.log('🌴 Page Builder Data', pageBuilderData);

  if (typeof children === 'function') {
    return children({
      data,
      pageTitle,
      template,
      path,
      acf,
      seo,
      siteMeta,
      pageBuilderData,
      pageHeader,
    });
  }

  return (
    <PageLayout
      template={template}
      siteMeta={siteMeta}
      pageHeader={pageHeader}
    >
      <PageBuilder data={pageBuilderData} meta={{ pageChildren, page }} components={components} />
    </PageLayout>
  )
}

export default Page;

export const pageQuery = graphql`
  query PageById($id: String!, $wordpressId: Int!) {
    allWordpressPage(filter: { wordpress_parent: { eq: $wordpressId } }) {
      nodes {
        path
        acf {
          page_header_primary_title
          page_header_secondary_title
          list_title
          list_subtitle
        }
      }
    }
    wordpressPage(id: { eq: $id }) {
      template
      title
      content
      path
      acf {
        components {
          key
          name
          component
        }
        ...PageHeader
        seo {
          title
          description
          no_robots
          image {
            localFile {
              childImageSharp {
                fixed(width: 600, height: 315) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        page_builder_page {
          ...PageBuilder
        }
      }
    }
  }
`
